<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="container vert-offset-top-1">
        <div class="row justify-content-center">
            <div class="col s12 m10 l8 offset-m1 offset-l2">

                <div class="card col s12 vert-offset-top-1 padding-top-half">

                    <div class="col s12 card-title left-align padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black-text homeboy-font align-middle">
                        <div class="col s2 m2 no-padding">
                            <router-link class="black-text left-align" to="/pin/reset/request">
                                <i class="material-icons left">chevron_left</i>
                            </router-link>
                        </div>
                        <div class="col s10 m10 no-padding left-align font-16">
                            Reset Your Pin
                        </div>
                    </div>
                    <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">
                        <div class="row">
                            <form>
                                <div class="row vert-offset-bottom-half">
                                    <div class="row col s12 m5 vert-offset-top-1">
                                        <custom-select-component ref="country" v-model="selected_country" :options="countries"  title="Type to select your Country"></custom-select-component>
                                    </div>
                                    <div class="col s12 m7 vert-offset-bottom-half">
                                        <input-component id="mobile" type="tel" v-model="mobile" label="Mobile Number" required="required"></input-component>
                                    </div>

                                </div>
                                <div class="row vert-offset-bottom-half">

                                    <div class="col s12">
                                        <input-component  id="otp" type="password" v-model="otp" label="One Time Pin" required="required" helper="The One Time Pin is a pin sent to your mobile number."></input-component>
                                    </div>
                                </div>
                                <div class="row vert-offset-bottom-half">

                                    <div class="col s12 m6">
                                        <input-component id="new_pin" type="password" v-model="new_pin" label="New PIN" required="required"></input-component>
                                    </div>

                                    <div class="col s12 m6">
                                        <input-component id="confirmed_pin" type="password" v-model="confirmed_pin" label="Confirm New PIN" required="required" ></input-component>
                                    </div>
                                </div>

                                <div class="row vert-offset-bottom-half">
                                    <div class="col s12 center">
                                        <button @click.prevent="confirmPinReset" class="btn-small homeboy-gold homeboy-charcoal-black-text">
                                            Reset Pin
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../../http-common"
    import { bus } from "../../main"
    import InputComponent from "@/components/materialize/InputComponent.vue"
    import {mapState} from "vuex"
    import CustomSelectComponent from "@/components/materialize/CustomSelectComponent.vue"

    export default {
        name: 'ResetPin',
        components:{
            InputComponent,CustomSelectComponent
        },
        data(){
            return{
                otp:"",
                new_pin:"",
                confirmed_pin:"",
                mobile:"",
                selected_country:""
            }
        },
        created(){
            console.log("LOGGING HERE");
            this.getCountries();

            bus.$off('confirmAction');

            bus.$on('confirmAction', (_data) => {
                if(_data == "pinReset")
                {
                    /*console.log("Trigger Save Pin reset");*/
                    this.resetPin();
                }
                this.$store.dispatch('SET_ACTION', '');
            });
            //this.confirmPinChange();
        },
        mounted(){
            this.selected_country = "BW";
        },
        computed:{
            ...mapState({
                customer:state=>state.customers.customerInfo,
                user:state=>state.users.organisation_user,
                countries:state=>state.loyaltolocal.l2l_countries
            }),
        },
        methods:{
            confirmPinReset: function()
            {
                /*console.log("Event Triggered: pinReset");*/
                if(this.otp !== "" && this.new_pin !== "" && this.confirmed_pin !== "" && this.mobile !== "" && this.selected_country !== "" )
                {
                    this.$store.dispatch('SET_ACTION', 'pinReset');
                }
                else {
                    alert("Please enter all required fields.")
                }
            },
            resetPin:function(){
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {

                    http({
                        url: '/customers/password/reset',
                        data:{
                            code:this.otp,
                            pin:this.new_pin,
                            pin_confirmation:this.confirmed_pin,
                            country:this.selected_country,
                            msisdn:this.mobile,
                            source: this.$source
                        },
                        method: 'POST',
                        timeout: 30000
                    }).then
                    (resp => {

                        this.$store.dispatch('STOP_PROCESSING');
                        //this.customer_services = resp.data.data;
                        console.log("DATA : "+resp.data.data);
                        this.$store.dispatch('GET_MESSAGES',['Pin successfully changed.']);

                        this.otp="";
                        this.new_pin="";
                        this.confirmed_pin="";
                        this.mobile="";


                        resolve(resp)
                    }).catch((response) =>
                    {
                        console.log("ErrResponse: "+JSON.stringify(response.data));
                        this.$store.dispatch('STOP_PROCESSING');
                        this.$store.dispatch('GET_MESSAGES',response.data.messages);
                        //reject(err)
                    })
                })
            },
            getCountries:function () {
                this.$store.dispatch("GET_L2L_COUNTRIES_REQUEST");
            }


        }
    }
</script>
